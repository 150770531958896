/* helper */
const timeToMillisecond = (time: string) => {
    const number = +time.substring(0, time.indexOf(' '));
    switch (time.substr(time.indexOf(' ') + 1)) {
        case 'day':
            return number * 24 * 60 * 60 * 1000;
        case 'hour':
            return number * 60 * 60 * 1000;
        case 'minute':
            return number * 60 * 1000;
        case 'second':
            return number * 1000;
        default:
            return number * 60 * 1000;
    }
};
const parseValue = (value: string) => {
    try {
        return JSON.parse(value);
    } catch (_) { return {} }
}

const COOKIE_TOKEN = "se_talk_ai_token";
const COOKIE_PROFILE_INFO = "se_talk_ai_profile_info";
const COOKIE_LOGIN_DATA = "se_talk_ai_login_data";
const COOKIE_REGISTRATION_DATA = "se_talk_ai_registration_data";

/* public conversation */
const ALL_PUBLIC_CONVERSATION_LIST = "se_talk_ai_public_conversation_list";
const PUBLIC_CHAT = "se_talk_ai_public_chat";

const setCookie = (token: string, value: string, exTime: string = '1 day') => {
    const date = new Date();
    date.setTime(date.getTime() + timeToMillisecond(exTime));
    const expires = 'expires=' + date.toUTCString();
    if (typeof document) {
        document.cookie = token + '=' + value + ';' + expires + ';path=/';
    }
}
const getCookie = (token: string) => {
    try {
        if (typeof document) {
            const name = token + '=';
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') { c = c.substring(1); }
                if (c.indexOf(name) === 0) { return c.substring(name.length, c.length) || ''; }
            }
            return ""
        } else { return ""; }
    } catch (_) { return ''; }
};
const unsetCookies = (token: string) => {
    let d = new Date();
    d.setDate(d.getDate() - 1);
    if (typeof document) {
        document.cookie = token + `=; expires=${d}; path=/;`;
    }
};

const setWithEncode = (token: string, value: string, exTime: string = '1 day') => {
    const d = new Date();
    d.setTime(d.getTime() + timeToMillisecond(exTime));
    const expires = 'expires=' + d.toUTCString();
    if (typeof document) {
        document.cookie = token + '=' + btoa(value) + ';' + expires + ';path=/';
    }
}
const getWithEncode = (token: string) => {
    try {
        if (typeof document) {
            const name = token + '=';
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') { c = c.substring(1); }
                if (c.indexOf(name) === 0) { return window.atob(c.substring(name.length, c.length)); }
            }
        } else { return; }
    } catch (_) { return null; }
}

const setApiToken = (value: string) => { setWithEncode(COOKIE_TOKEN, value); }
const getApiToken = () => { return getWithEncode(COOKIE_TOKEN); }
const resetApiToken = () => { unsetCookies(COOKIE_TOKEN) }

const setProfileData = (value: object) => { setCookie(COOKIE_PROFILE_INFO, JSON.stringify(value)); }
const getProfileData = () => { return parseValue(getCookie(COOKIE_PROFILE_INFO)); }
const resetProfileData = () => { unsetCookies(COOKIE_PROFILE_INFO) }

const setLoginData = (value: object) => { setCookie(COOKIE_LOGIN_DATA, JSON.stringify(value)); }
const getLoginData = () => { return parseValue(getCookie(COOKIE_LOGIN_DATA)); }

const setRegistrationData = (value: object) => { setCookie(COOKIE_REGISTRATION_DATA, JSON.stringify(value), '10 minute'); }
const getRegistrationData = () => { return parseValue(getCookie(COOKIE_REGISTRATION_DATA)); }

/* public conversation data */
const setPublicConversationList = (value: any, id: any) => { setCookie(ALL_PUBLIC_CONVERSATION_LIST+id, JSON.stringify(value), '365 day'); }
const getPublicConversationList = (id: any) => { return parseValue(getCookie(ALL_PUBLIC_CONVERSATION_LIST+id)); }
const setPublicChat = (value: any, id: any) => { setCookie(PUBLIC_CHAT+id, JSON.stringify(value), '365 day'); }
const getPublicChat = (id: any) => { return parseValue(getCookie(PUBLIC_CHAT+id)); }

export {
    getCookie, setCookie, unsetCookies,
    setWithEncode, getWithEncode,
    setApiToken, getApiToken, resetApiToken,
    setProfileData, getProfileData, resetProfileData,
    setLoginData, getLoginData,
    setRegistrationData, getRegistrationData,
    setPublicConversationList, getPublicConversationList,
    setPublicChat, getPublicChat
}